<template>
  <component
    :is="tooltipOrDiv"
    v-bind="tooltipProps"
    :style="fullComponentWidth"
    :class="[
      {
        'bitts-avatar-stack__transparent': transparent,
      },
      `bitts-avatar-stack-${size}`,
    ]"
  >
    <div class="bitts-avatar-stack__org-list h-full">
      <div class="flex items-center">
        <BittsAvatar
          v-for="(org, index) in orgListTruncated"
          :key="`avatar-stack-org__${org?.id || index}`"
          :avatar-classes="allAvatarClasses"
          :org="org"
          :alt-text-from-parent="org.altTextFromParent"
          :image-source="org.imageSource"
          :custom-svg="org.customSvg"
          :show-initials="org.showInitials"
          :style="getStyleForAvatar(index)"
          :size="size"
          shape="square"
          class="bitts-avatar-stack__org-list-org"
        />
      </div>
      <div
        v-if="numberOfOverflowOrgs"
        :style="styleForOverflowNumber"
        class="c-bitts-avatar-stack__overflow-number"
      >
        {{ `+${numberOfOverflowOrgs}` }}
      </div>
    </div>
    <template #title>
      <div class="bitts-avatar-stack__tooltip">
        <div
          v-for="org in orgList"
          :key="`avatar-stack-tooltip__${org.id}`"
          class="bitts-avatar-stack__tooltip-orgs"
        >
          <BittsAvatar
            :org="org"
            :avatar-classes="tooltipAvatarClasses"
            :alt-text-from-parent="org.altTextFromParent"
            :image-source="org.imageSource"
            :show-initials="org.showInitials"
            size="x-small"
            shape="square"
            class="mr-8"
          />
          {{ org.name }}
        </div>
      </div>
    </template>
  </component>
</template>

<script>
import BittsAvatar from '../BittsAvatar/BittsAvatar.vue';
import BittsTooltip from '../BittsTooltip/BittsTooltip.vue';

const MAX_LENGTH_FOR_TRUNCATED_ORGLIST = 5;

const ORGLIST_COUNT_TO_LIST_MAP = {
  // width of the stack itself
  small: {
    greaterThan5: 104,
    5: 88,
    4: 74,
    3: 60,
    2: 46,
    1: 32,
  },
  medium: {
    greaterThan5: 180,
    5: 162,
    4: 132,
    3: 105,
    2: 77,
    1: 86,
  },
  large: {
    greaterThan5: 350,
    5: 320,
    4: 265,
    3: 215,
    2: 155,
    1: 165,
  },
};

const OVERLAPPING_ORG_WIDTH = {
  small: 14,
  medium: 18,
  large: 34,
};

const SINGLE_DIGIT_LENGTH = 8;

export default {
  name: 'BittsAvatarStack',
  components: {
    BittsAvatar,
    BittsTooltip,
  },
  props: {
    avatarClasses: {
      type: String,
      default: '',
    },
    includeHover: {
      type: Boolean,
      default: false,
    },
    /* according to spec, this will only ever be orgs
     this is coded such that the individual props for each avatar is coded within each org
     so, a potential org would look like:
     {
         ...org,
         showInitials: {{ is not a record }},
         altTextForParent: {{ is there nonstandard alt text we want }},
         ...etc
     }
    besides avatarClasses, because we want those to be uniform */
    orgList: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: 'small',
      validator(value) {
        return ['small', 'medium', 'large'].includes(value);
      },
    },
    tooltipAvatarClasses: {
      type: String,
      default: '',
    },
    transparent: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    allAvatarClasses() {
      return `${this.avatarClasses} bitts-avatar-stack__outline-class`;
    },
    fullComponentWidth() {
      let width = ORGLIST_COUNT_TO_LIST_MAP[this.size][this.orgList.length];
      if (this.numberOfOverflowOrgs) {
        width = ORGLIST_COUNT_TO_LIST_MAP[this.size].greaterThan5;
        const overflowNumberDigits = this.numberOfOverflowOrgs
          .toString()
          .split('').length;
        width += overflowNumberDigits * SINGLE_DIGIT_LENGTH;
      }
      return {
        width: `${width}px`,
      };
    },
    numberOfOverflowOrgs() {
      if (this.orgList.length > MAX_LENGTH_FOR_TRUNCATED_ORGLIST) {
        return this.orgList.length - MAX_LENGTH_FOR_TRUNCATED_ORGLIST;
      }
      return null;
    },
    orgListTruncated() {
      return this.orgList.slice(0, MAX_LENGTH_FOR_TRUNCATED_ORGLIST);
    },
    styleForOverflowNumber() {
      // this is always going to be 5
      const indexLength = MAX_LENGTH_FOR_TRUNCATED_ORGLIST;
      /* we don't want this to overlap like the orgs, so we are moving it over first
      and then moving back right the length of an overlapped org icon */
      const leftAmount =
        indexLength * this.multiplierForLeftOffset +
        OVERLAPPING_ORG_WIDTH[this.size];
      return {
        left: `${leftAmount}px`,
      };
    },
    tooltipOrDiv() {
      return this.includeHover ? 'BittsTooltip' : 'div';
    },
    tooltipProps() {
      const props = {};
      if (this.includeHover) {
        props.trigger = 'hover';
        props.placement = 'bottomLeft';
        props.overlayClass = 'bitts-avatar-stack';
        props.mountToBody = true;
      }
      return props;
    },
    multiplierForLeftOffset() {
      if (this.size === 'medium') return -12;
      if (this.size === 'large') return -24;
      return -10;
    },
  },
  methods: {
    getStyleForAvatar(index) {
      // each subsequent avatar needs to be moved to the left proportional to its position
      const leftAmount = index * this.multiplierForLeftOffset;
      return {
        left: `${leftAmount}px`,
      };
    },
  },
};
</script>

<style lang="pcss">
.bitts-avatar-stack.ant-tooltip {
  @apply shadow-overlay;
}
.bitts-avatar-stack-small {
  .ant-tooltip-inner {
    @apply opacity-100 bg-neutral-background-strong;
  }

  .bitts-avatar-stack__org-list {
    @apply flex flex-row items-center p-4 pr-0 rounded-bts-sm;
  }

  .bitts-avatar-stack__org-list-org {
    @apply relative;
  }

  .bitts-avatar-stack__outline-class {
    outline: solid 2px white;
  }

  .bitts-avatar-stack__tooltip {
    @apply flex items-center flex-wrap;
    max-width: 250px;
  }

  .bitts-avatar-stack__tooltip-orgs {
    @apply flex items-center mr-8;
  }
  .c-bitts-avatar-stack__overflow-number {
    @apply text-neutral-text-weak relative;
  }
}

.bitts-avatar-stack-medium {
  .bitts-avatar-stack__org-list {
    @apply flex flex-row items-center p-4 pr-0 rounded-bts-md;
  }
  .ant-tooltip-inner {
    @apply opacity-100 bg-neutral-background-strong;
  }
  .bitts-avatar-stack__org-list-org {
    @apply relative;
  }

  .bitts-avatar-stack__outline-class {
    outline: solid 2px white;
  }

  .bitts-avatar-stack__tooltip {
    @apply flex items-center flex-wrap;
    max-width: 250px;
  }

  .bitts-avatar-stack__tooltip-orgs {
    @apply flex items-center mr-8;
  }
  .c-bitts-avatar-stack__overflow-number {
    @apply text-neutral-text-weak relative text-m;
  }
}
.bitts-avatar-stack-large {
  .bitts-avatar-stack__org-list {
    @apply flex flex-row items-center p-8 pr-0 rounded-bts-lg;
  }
  .ant-tooltip-inner {
    @apply opacity-100 bg-neutral-background-strong;
  }
  .bitts-avatar-stack__org-list-org {
    @apply relative;
  }

  .bitts-avatar-stack__outline-class {
    outline: solid 4px white;
  }

  .bitts-avatar-stack__tooltip {
    @apply flex items-center flex-wrap;
    max-width: 250px;
  }
  .c-bitts-avatar-stack__overflow-number {
    @apply text-neutral-text-weak relative text-lg;
  }
}

.bitts-avatar-stack__transparent {
  .bitts-avatar-stack__org-list {
    @apply p-0 bg-white;
  }
}

.bitts-avatar-stack__tooltip-orgs {
  @apply flex items-center mr-8;
}
</style>
